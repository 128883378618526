<template>
  <div>
    <k-crud-layout :search.sync="searchQuery" :filters.sync="filters">
      <template #header>{{ $t('store.menuTitle') }}</template>
      <template #flow-actions>
        <v-btn color="primary" depressed tile outlined @click="handleOpenImportDialog" class="mr-3">
          {{ $tc('store.import') }}
        </v-btn>
        <v-btn color="primary" depressed tile @click="handleOpenCreateDialog">
          {{ $tc('store.create') }}
        </v-btn>
        <StoreForm v-model="createDialog"
                   :request="createRequest"
                   :title="$t('store.create')"
                   :values="createFormValues"
                   @change="$refs.table.reload()"/>
        <ImportForm v-model="importDialog"
                    ref="importDialog"
                    :title="$t('store.import')"
                    @change="$refs.table.reload()"/>
      </template>
      <template #filters="{attrs, on}">
        <StoresFilter
            v-bind="attrs"
            v-on="on"
        />
      </template>
      <template #view.list>
        <k-crud-table ref="table"
                      :headers="crudHeaders"
                      :index-request="indexRequest"
                      :search="searchQuery"
                      :params="filters"
                      language-prefix="store.headers"
                      resource="store"
                      @click:edit="openUpdate"
                      @click:row="openUpdate">
          <template #actions="{item}">
            <v-btn
              @click="setCurrentStore(item)"
              icon
            >
              <v-icon>$visible</v-icon>
            </v-btn>
          </template>
        </k-crud-table>
      </template>

    </k-crud-layout>

    <StoreForm v-model="updateDialog"
               :request="updateRequest"
               :title="$t('store.update')"
               :values="updateFormValues"
               is-update-form
               @change="$refs.table.reload()"/>
  </div>
</template>

<script lang="js">
import { Store } from '@/application/models/Store.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/store/api';
import StoreForm from '@/modules/store/components/StoreForm.vue';
import ImportForm from '@/modules/store/components/ImportForm.vue';
import { mapMutations } from 'vuex';
import StoresFilter from '@/modules/store/components/StoresFilter.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';

export default {
  name: 'StoreTable',
  mixins: [querySyncMixin],
  components: {
    StoresFilter,
    ImportForm,
    KCrudTable,
    KCrudLayout,
    StoreForm,
  },
  data() {
    return {
      filters: {},
      searchQuery: '',
      createDialog: false,
      importDialog: false,
      createFormValues: new Store(),
      dialogOpen: false,
      updateDialog: false,
      updateFormValues: new Store(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'dealerCodeExt',
          language: 'dealerCodeExt',
        },
        {
          value: 'name',
          language: 'name',
        },
        {
          value: 'country',
          language: 'country',
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      setCurrentStoreId: 'settings/setCurrentStoreId',
    }),
    handleOpenCreateDialog() {
      this.createFormValues = new Store();
      this.createDialog = true;
    },
    handleOpenImportDialog() {
      this.$refs.importDialog.reset();
      this.importDialog = true;
    },
    createRequest() {
      return create(this.createFormValues);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    indexRequest: index,
    async openUpdate(item) {
      this.updateFormValues = new Store();
      const showRequest = await show(item.id);
      this.updateFormValues.mapResponse(showRequest.data.data);
      this.updateDialog = true;
    },
    setCurrentStore(item) {
      this.setCurrentStoreId(item.id);
      this.$router.push({ name: 'home' });
    }
  },
};
</script>
