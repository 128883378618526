import { render, staticRenderFns } from "./ImportForm.vue?vue&type=template&id=985fd37a&scoped=true&"
import script from "./ImportForm.vue?vue&type=script&lang=js&"
export * from "./ImportForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "985fd37a",
  null
  
)

export default component.exports