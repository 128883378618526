<template>
  <k-field-group language-prefix="store.fields">
    <k-form-dialog v-bind="$attrs" v-on="$listeners" :panels="panels">
      <template #panel.0>
        <v-row class="fill-height">
          <v-row class="flex-nowrap">
            <v-col cols="8">
              <form-sheet class="fill-height">
                <strong>{{ $t('store.headers.companyInfo').toUpperCase() }}</strong>
                <KTextField v-model="values.name" field="storeName" required/>
                <KTextField v-model="values.dealerLevel" field="dealerLevel"/>
                <KTextField v-model="values.dealerCode" field="dealerCode"/>
                <KTextField v-model="values.branchExtension" field="brancheExtension"/>
                <StoreOwnersAutocomplete :return-object="false"
                                         v-model="values.storeOwners"
                                         field="orderers"
                                         multiple
                                         required
                                         chips
                                         deletable-chips/>
              </form-sheet>
            </v-col>
            <v-col cols="7">
              <form-sheet class="fill-height">
                <UsersAutocomplete v-model="selectedApprover" field="approvers"/>
                <strong>{{ $t('store.headers.approvals').toUpperCase() }}</strong>
                <ApproversAutocomplete v-model="selectedApprover" field="approvers"/>
                <v-row no-gutters justify="end">
                  <v-btn color="primary" depressed tile :disabled="!canAddApprover" @click="addApprover">
                    {{ $t('store.actions.addApprover') }}
                  </v-btn>
                </v-row>
                <div v-if="values.approvers.length === 0" class="text--secondary text-caption font-italic my-6">
                  {{ $t('store.text.noApproversSelected') }}
                </div>
                <ApproversDraggable v-else class="my-6" v-model="values.approvers" required/>
              </form-sheet>
            </v-col>
          </v-row>

          <v-row class="flex-nowrap">
            <v-col cols="5">
              <form-sheet class="fill-height">
                <strong>{{ $t('store.headers.showroomLocation').toUpperCase() }}</strong>
                <KTextField v-model="values.showroomAddress.addressLine1" :label="$t('address.addressLine1')" required/>
                <KTextField v-model="values.showroomAddress.addressLine2" :label="$t('address.addressLine2')"/>
                <KTextField v-model="values.showroomAddress.zipCode" :label="$t('address.zipCode')" required/>
                <KTextField v-model="values.showroomAddress.city" :label="$t('address.city')" required/>
                <CountryAutocomplete :return-object="false"
                                     v-model="values.showroomAddress.countryId"
                                     :label="$t('address.countryId')"
                                     required/>
              </form-sheet>
            </v-col>
            <v-col cols="5">
              <form-sheet class="fill-height">
                <strong>{{ $t('store.headers.shippingAddress').toUpperCase() }}</strong>
                <KTextField v-model="values.shippingAddress.addressLine1" :label="$t('address.addressLine1')" required/>
                <KTextField v-model="values.shippingAddress.addressLine2" :label="$t('address.addressLine2')"/>
                <KTextField v-model="values.shippingAddress.zipCode" :label="$t('address.zipCode')" required/>
                <KTextField v-model="values.shippingAddress.city" :label="$t('address.city')" required/>
                <CountryAutocomplete :return-object="false"
                                     v-model="values.shippingAddress.countryId"
                                     :label="$t('address.countryId')"
                                     required/>
                <v-row justify="end" class="mt-4 mb-2 mr-1">
                  <v-btn small text tile @click="values.shippingAddress.mapResponse({...values.showroomAddress})">
                    {{ $t('store.actions.copyFromInvoice') }}
                  </v-btn>
                </v-row>
              </form-sheet>
            </v-col>
            <v-col cols="5">
              <form-sheet class="fill-height">
                <strong>{{ $t('store.headers.billingAddress').toUpperCase() }}</strong>
                <KTextField v-model="values.billingAddress.addressLine1" :label="$t('address.addressLine1')" required/>
                <KTextField v-model="values.billingAddress.addressLine2" :label="$t('address.addressLine2')"/>
                <KTextField v-model="values.billingAddress.zipCode" :label="$t('address.zipCode')" required/>
                <KTextField v-model="values.billingAddress.city" :label="$t('address.city')" required/>
                <CountryAutocomplete :return-object="false"
                                     v-model="values.billingAddress.countryId"
                                     :label="$t('address.countryId')"
                                     required/>
                <KTextField v-model="values.phoneNumber" field="phone"/>
                <KTextField v-model="values.email" field="emailAddress" required/>
              </form-sheet>
            </v-col>
          </v-row>
        </v-row>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import { User } from '@/application/models/User.js';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import FormSheet from '@/components/FormSheet.vue';
import CountryAutocomplete from '@/modules/country/components/CountryAutocomplete.vue';
import UsersAutocomplete from '@/modules/store/components/UsersAutocomplete.vue';
import ApproversDraggable from '@/modules/store/components/ApproversDraggable.vue';
import StoreOwnersAutocomplete from '@/modules/user/components/StoreOwnersAutocomplete.vue';

export default {
  name: 'StoreForm',
  components: {
    StoreOwnersAutocomplete,
    CountryAutocomplete,
    UsersAutocomplete,
    ApproversDraggable,
    FormSheet,
    KFieldGroup,
    KTextField,
    KFormDialog,
  },
  data() {
    return {
      selectedApprover: null,
    };
  },
  props: {
    values: {
      type: Object,
    },
  },
  computed: {
    panels() {
      return [
        { name: this.$t('global.defaultTabName') },
      ];
    },
    canAddApprover() {
      const isUserSelected = this.selectedApprover instanceof User;
      if (!isUserSelected) return false;
      const alreadyPresent = Array.isArray(this.values.approvers) && this.values.approvers.find(({ id }) => id === this.selectedApprover.id);
      return !alreadyPresent && isUserSelected;
    },
  },
  methods: {
    addApprover() {
      const {
        name,
        id,
      } = this.selectedApprover;
      this.values.approvers.push({
        id,
        text: name,
      });
    },
  },
};
</script>
